import React from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Input, FormFeedback, Label, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import contactbgimg from "../images/contactbg_img.jpg";
import { useForm, Controller } from "react-hook-form";
import consoleService from "../../utility/services/consoleService";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contact_us } from "../../component/services/ApiCalls";

const Contacts = () => {
  // INFO FORM FIELDS

  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  };

  const infoForm = useForm({ defaultValues });
  const infoControl = infoForm.control;
  const setinfoFormErr = infoForm.setError;
  const handleInfoFormSubmit = infoForm.handleSubmit;
  const infoFormErr = infoForm.formState.errors;
  const resetForm = infoForm.reset; 

  const onSubmitInfoForm = async (data) => {
    if (
      Object.values(data).every((field) => field.toString().trim().length > 0)
    ) {
      try {
        contact_us(data)
          .then((res) => {
            if (res.status == 1) {
              toast("Thanks, We will be get in touch with you asap");
              resetForm(); 
            }else{
              throw new Error(
                    `API request failed with status ${res.status}`
                  );
            }          
          })
          .catch((err) => {
            console.log("err", err);
          });
       
      } catch (err) {
        consoleService("error in onSubmitInfoForm function:", err);
      }
    } else {
      for (const key in data) {
        if (data[key].length === 0) {
          setinfoFormErr(key, {
            type: "manual",
            message: `${key} is required`,
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <section className="contactmain_div">
        <Container>
          <Row>
            <Col md={10} lg={8}>
              <div className="formtoday_div">
                <h4 className="contact_head">Get in touch today</h4>
                <Form onSubmit={handleInfoFormSubmit(onSubmitInfoForm)}>
                  <Row>
                    <Col md={6}>
                      <FormGroup
                        className="form_group"
                        controlId="formGroupName"
                      >
                        <Label>Name</Label>
                        <Controller
                          id="name"
                          name="name"
                          control={infoControl}
                          render={({ field }) => (
                            <Input
                              type="text"
                              placeholder="John Carter"
                              invalid={infoFormErr.name && true}
                              {...field}
                            />
                          )}
                        />
                        {infoFormErr.name && (
                          <FormFeedback className="validation_txt">
                            {infoFormErr.name.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup
                        className="form_group"
                        controlId="Form_group_email"
                      >
                        <Label>Email</Label>
                        <Controller
                          id="email"
                          name="email"
                          control={infoControl}
                          render={({ field }) => (
                            <Input
                              type="email"
                              placeholder="example@email.com"
                              invalid={infoFormErr.email && true}
                              {...field}
                            />
                          )}
                        />
                        {infoFormErr.email && (
                          <FormFeedback className="validation_txt">
                            {infoFormErr.email.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup
                        className="form_group"
                        controlId="Form_group_phone"
                      >
                        <Label>Phone</Label>
                        <Controller
                          id="phone"
                          name="phone"
                          control={infoControl}
                          // rules={{
                          //   pattern: {
                          //     value: /^[0-9]*$/,
                          //     message: "Only numbers are allowed",
                          //   },
                          //   maxLength: {
                          //     value: 14,
                          //     message: "Maximum length is 14 characters",
                          //   },
                          // }}
                          render={({ field }) => (
                            <Input
                              type="text"
                              placeholder="(+33) 456 - 789"
                              invalid={infoFormErr.phone && true}
                              {...field}
                            />
                          )}
                        />
                        {infoFormErr.phone && (
                          <FormFeedback className="validation_txt">
                            {infoFormErr.phone.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup
                        className="form_group"
                        controlId="Form_group_company"
                      >
                        <Label>Company</Label>
                        <Controller
                          id="company"
                          name="company"
                          control={infoControl}
                          render={({ field }) => (
                            <Input
                              type="text"
                              placeholder="Facebook"
                              invalid={infoFormErr.company && true}
                              {...field}
                            />
                          )}
                        />
                        {infoFormErr.company && (
                          <FormFeedback className="validation_txt">
                            {infoFormErr.company.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup
                        className="form_group"
                        controlId="Form_group_message"
                      >
                        <Label>Leave us a message</Label>
                        <Controller
                          id="message"
                          name="message"
                          control={infoControl}
                          render={({ field }) => (
                            <Input
                              type="text"
                              placeholder="Please type your message here..."
                              invalid={infoFormErr.message && true}
                              {...field}
                            />
                          )}
                        />
                        {infoFormErr.message && (
                          <FormFeedback className="validation_txt">
                            {infoFormErr.message.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <Button
                        type="submit"
                        variant="primary"
                        className="contact_btn mt-2"
                      >
                        Send message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="contactinfo_div">
                <h4 className="contact_head">Or Contact Us Directly</h4>
                <h5 className="contact_subhead">Mottruck Sas</h5>
                <Row>
                  <Col xs={12} sm={5} md={4} lg={3}>
                    <p>
                      59 Grande Rue <br />
                      75015 Paris
                    </p>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={5}>
                    <div className="conactmail_info">
                      <Link to="mailto:contact@mottruck.com">
                        contact@mottruck.com
                      </Link>
                      <Link to="tel:06 85 14 20 36">(+33) 06 85 14 20 36</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Image className="contactbg_img" src={contactbgimg} alt="" />
      </section>
      <ToastContainer> </ToastContainer>
    </React.Fragment>
  );
};

export default Contacts;
