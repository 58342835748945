import { useState, useRef, useEffect, useContext, useMemo } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import { IoIosArrowDropdown } from "react-icons/io";

import {
  Col,
  Row,
  Button,
  Table,
  Image,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  Form,
  Container,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle, FiMoreVertical, FiClock, FiMenu } from "react-icons/fi";
import tablearrow from "../images/table_arrow.svg";
import truckicon from "../images/truck_icon.svg";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import TruckIcon from "../images/truck.svg";
import LocationIcon from "../images/location.svg";
import DeliveryIcon from "../images/delivery.svg";
import MatIcon from "../images/maticon.svg";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import TextInputWithoutLabel from "../customcomponents/TextInputWithoutLabel";
import TextInputAreaWithLabel from "../customcomponents/TextInputAreaWithLabel";
import { FaStar, FaRegStar } from "react-icons/fa";
import FilterSidebar from "../inc/FilterSidebar";
import FilterIcon from "../images/filter.svg";
import UserInfoIcon from "../images/userinfo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaL, FaLocationDot, FaRegCircleCheck } from "react-icons/fa6";
import { FaRegTimesCircle } from "react-icons/fa";
import SearchIcon from "../images/searchicon.svg";
import * as SERVICE from "../services/index";
import {
  add_Quote,
  country_PhoneNumberCode,
  delete_Quote,
  get_QuoteList,
  staff_AcceptProposed,
  staff_GiveCarrierList,
  decline_quote_by_user,
  staff_ClientList,
} from "../services/ApiCalls";
import { QuotesDataContext } from "../services/QuotesData";
import NewQouteModalOne from "../commonModal/NewQouteModalOne";
import NewQouteModalOneTwo from "../commonModal/NewQouteModalOneTwo";
import NewQouteModalOneTHree from "../commonModal/NewQouteModalOneTHree";
import moment from "moment";
import LoadingContext from "../services/LoadingContext";
import NotificationIcon from "../images/notification.svg";
import EditQouteModal from "../commonModal/EditQouteModal";
import ReactPaginate from "react-js-pagination";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import {
  user_AddQuote,
  user_DeleteQuote,
  user_QuoteList,
} from "../services/UserApiServices/UserApiCalls";
import ViewQuoteDetails from "../commonModal/ViewQuoteDetails";
import Loader from "../../common/component/Loader";
import consoleService from "../../utility/services/consoleService";

import CustomHookFunctions from "../services/CustomHookFunctions";
import { AppStoreContext } from "../../store/AppStore";
import ReactStarsRating from "react-awesome-stars-rating";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import { XCircle } from "react-feather";

const Quotes = () => {
  const { userDetail } = useContext(AppStoreContext);
  const userId = userDetail._id;
  const userRole = userDetail.profile;
  const isRoleStaff = userRole == "Staff";
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [show_delete_modal, setShowDeleteModal] = useState(false);
  const [
    selected_quote_details_for_delete,
    setSelectedQuote_deltails_for_delete,
  ] = useState({});
  const [viewDetialModal, setViewDetailModal] = useState(false);
  const [directmodalShow, setDirectShow] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [quoteListData, setQuoteListData] = useState([]);
  const [quotesListForFilter, setQuotesListForFilter] = useState([]);
  const [selected_carrier_index, setSelectedCarrierIndex] = useState(null);
  const [mainIndex, setMainIndex] = useState("");
  const [toggle, setToggle] = useState(false);
  const [editId, setEditId] = useState("");
  const userDeatildata = userDetail;

  const { setIsLoading } = useContext(LoadingContext);
  const [deleteQuoteLoading, setDeleteQuoteLoading] = useState(false);
  const [accept_quote_loading, setAcceptQuoteLoading] = useState(false);
  const [decline_quote_loading, setDeclineQuoteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isBodyClassActive, setIsBodyClassActive] = useState(false);
  let count = 0;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedQuoteList = quoteListData.slice(startIndex, endIndex);

  const [selectedIndexForAcceptQuote, setSelectedIndexForAcceptQuote] =
    useState(null);

  const accept_quote_div_ref = useRef(new Map());
  const carrier_container_ref = useRef(new Map());

  const calculateCount = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const list_row_limit = 10;
  const { Paginated_data, isValidString } = CustomHookFunctions();

  const List_data = Paginated_data(currentPage, list_row_limit, quoteListData);

  useEffect(() => {
    fetchQuoteList(true);
  }, [directmodalShow, modalShow2, editShowModal]);

  const handleClickOutside = (event) => {
    let clickedOutside = true;
    let notClickOnCarrierContainer = true;
    accept_quote_div_ref.current.forEach((ref) => {
      if (ref && ref.contains(event.target)) {
        clickedOutside = false;
      }
    });
    carrier_container_ref.current.forEach((ref) => {
      if (ref && ref.contains(event.target)) {
        notClickOnCarrierContainer = false;
      }
    });
    const isClickedOutside = clickedOutside && notClickOnCarrierContainer;

    if (isClickedOutside) {
      setSelectedIndexForAcceptQuote(null);
      accept_quote_div_ref.current = new Map();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Quote List
  const fetchQuoteList = async (loading) => {
    try {
      const payload = {
        user_id: userId,
      };
      setIsLoading(loading);
      const res = await get_QuoteList(payload);
      if (res.status === true) {
        setQuoteListData(res.data);
        setQuotesListForFilter(res.data);
        setIsLoading(false);
        return;
      } else {
        alert(res.msg);
        setIsLoading(false);
      }
    } catch (err) {
      consoleService("err in fetchQuoteList =", err);
      setIsLoading(false);
    }
  };

  // HANDLE SEARCH

  const handleSearch = (searchQuery) => {
    const search_query = searchQuery.toLowerCase();
    if (searchQuery.trim()) {
      const filteredResults = quotesListForFilter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];
        const materialInfo = result.MaterialInformation;

        const loading_city = (
          isValidString(loadingInfo.loadingCity)
            ? loadingInfo.loadingCity.toString()
            : ""
        ).toLowerCase();

        const loading_country = (
          isValidString(loadingInfo.loadingCountry)
            ? loadingInfo.loadingCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_city = (
          isValidString(deliveryInfo.deliveryCity)
            ? deliveryInfo.deliveryCity.toString()
            : ""
        ).toLowerCase();

        const delivery_country = (
          isValidString(deliveryInfo.deliveryCountry)
            ? deliveryInfo.deliveryCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_state = (
          isValidString(deliveryInfo.deliveryState)
            ? deliveryInfo.deliveryState.toString()
            : ""
        ).toLowerCase();

        const category = (
          isValidString(result.category) ? result.category.toString() : ""
        ).toLowerCase();

        let distance = (
          isValidString(result.distance)
            ? `${Number(result.distance).toFixed(0)} km`.toString()
            : ""
        ).toLowerCase();

        const matchCondition =
          loading_city.startsWith(search_query) ||
          loading_country.startsWith(search_query) ||
          delivery_city.startsWith(search_query) ||
          delivery_state.startsWith(search_query) ||
          category.startsWith(search_query) ||
          distance.startsWith(search_query) ||
          delivery_country.startsWith(search_query);

        if (matchCondition) {
          return result;
        }
      });
      setQuoteListData([...filteredResults]);
    } else {
      setQuoteListData([...quotesListForFilter]);
    }
  };

  const handleSideFilter = (filter_items) => {
    const isFilter = Object.keys(filter_items).length > 0;
    if (isFilter) {
      const {
        delivery_city,
        delivery_date,
        delivery_time,
        loading_city,
        loading_date,
        loading_time,
        vehicle_category,
      } = filter_items;

      const filteredResults = quotesListForFilter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];

        const filter_arr = [];

        // DELIVERY CITY

        if (delivery_city) {
          const found_delivery_city = (
            isValidString(deliveryInfo.deliveryCity)
              ? deliveryInfo.deliveryCity.toString()
              : ""
          ).toLowerCase();

          const delivery_state = (
            isValidString(deliveryInfo.deliveryState)
              ? deliveryInfo.deliveryState.toString()
              : ""
          ).toLowerCase();

          const delivery_country = (
            isValidString(deliveryInfo.deliveryCountry)
              ? deliveryInfo.deliveryCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            delivery_city == found_delivery_city ||
            delivery_city == delivery_state ||
            delivery_city == delivery_country;
          filter_arr.push(matchCondition);
        }

        // DELIVERY DATE

        if (delivery_date) {
          const found_delivery_date = (
            isValidString(deliveryInfo.delivery_Date)
              ? deliveryInfo.delivery_Date.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_date == found_delivery_date;
          filter_arr.push(matchCondition);
        }

        // DELIVERY TIME

        if (delivery_time) {
          const found_delivery_time = (
            isValidString(deliveryInfo.delivery_Hour)
              ? deliveryInfo.delivery_Hour.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_time == found_delivery_time;
          filter_arr.push(matchCondition);
        }

        // LOADING CITY

        if (loading_city) {
          const found_loading_city = (
            isValidString(loadingInfo.loadingCity)
              ? loadingInfo.loadingCity.toString()
              : ""
          ).toLowerCase();

          const found_loading_state = (
            isValidString(loadingInfo.loadingState)
              ? loadingInfo.loadingState.toString()
              : ""
          ).toLowerCase();

          const loading_country = (
            isValidString(loadingInfo.loadingCountry)
              ? loadingInfo.loadingCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            loading_city == found_loading_city ||
            loading_city == found_loading_state ||
            loading_city == loading_country;
          filter_arr.push(matchCondition);
        }

        // LOADING DATE

        if (loading_date) {
          const found_loading_date = (
            isValidString(loadingInfo.Date) ? loadingInfo.Date.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_date == found_loading_date;
          filter_arr.push(matchCondition);
        }

        // LOADING TIME

        if (loading_time) {
          const found_loading_time = (
            isValidString(loadingInfo.Hours) ? loadingInfo.Hours.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_time == found_loading_time;
          filter_arr.push(matchCondition);
        }

        // VEHICLE CATEGORY

        if (vehicle_category) {
          const found_category = (
            isValidString(result.category) ? result.category.toString() : ""
          ).toLowerCase();

          const matchCondition = vehicle_category == found_category;
          filter_arr.push(matchCondition);
        }

        const Final_condition = filter_arr.every((condition) => {
          return condition == true;
        });

        if (Final_condition) {
          return result;
        }
      });

      setQuoteListData([...filteredResults]);
    } else {
      setQuoteListData([...quotesListForFilter]);
    }
  };

  const [Logged, setIsLoggedIn] = useState(() => {
    const loggedIn = localStorage.getItem("logged");
    return !!loggedIn;
  });

  useEffect(() => {
    const handleLoginEvent = () => {
      const loggedIn = localStorage.getItem("logged");
      if (loggedIn !== "reset") {
        setQuoteListData(JSON.parse(loggedIn));
      } else {
        fetchQuoteList(true);
      }
    };

    window.addEventListener("onLoggedIn", handleLoginEvent);

    return () => {
      window.removeEventListener("onLoggedIn", handleLoginEvent);
    };
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      fetchQuoteList(true);
    }
  }, [searchQuery]);

  const toggleBodyClass = () => {
    setIsBodyClassActive(!isBodyClassActive);
  };

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const renderTooltip2 = (props) => <div></div>;

  const renderTooltip = (props) => {
    var checkName = props && props.Company_name;
    return (
      <Tooltip id="button-tooltip" className="toolTip" {...props}>
        <div style={{ backgroundColor: "#f7ce6c" }}>
          {userRole == "Staff" && (
            <>
              <span style={{ color: "#4f5b92" }}>{checkName}</span> <br />
            </>
          )}
          <span style={{ color: "grey" }}>{"Waiting for carrier pricing"}</span>
        </div>
      </Tooltip>
    );
  };

  const renderTooltip1 = (props) => {
    var checkName = props && props.companyName;

    const declinedBy = props.declined_by;

    const isDeclinedByUser = declinedBy == userId;
    return (
      <Tooltip id="button-tooltip" className="toolTip" {...props}>
        <span style={{ color: "#4f5b92" }}>{checkName}</span> <br />
        <span style={{ color: "grey" }}>
          {isDeclinedByUser
            ? "Carrier declined by you."
            : "Carrier declined mission."}
        </span>
      </Tooltip>
    );
  };

  const target = useRef(null);

  const target1 = useRef(null);

  // Delete API
  const handelDeleteClick = (id) => {
    setSelectedQuote_deltails_for_delete({
      qoutesId: id,
      deleteStatus: "1",
      type: isRoleStaff ? "staff" : "user",
    });
    setShowDeleteModal(true);
  };

  function delete_quote_fun() {
    try {
      setDeleteQuoteLoading(true);
      delete_Quote(selected_quote_details_for_delete)
        .then(async (res) => {
          if (res.status === true) {
            await fetchQuoteList(false);
            toast("Quote Delete successfully ");

            setShowDeleteModal(false);
          } else {
            toast(res.msg);
          }
          setDeleteQuoteLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (err) {
      consoleService("error in delete_quote_fun  =", err);
    }
  }

  const handleCarrier = (isShow, carrier_index, index) => {
    if (isShow) {
      setMainIndex(null);
      setSelectedCarrierIndex(null);
      setSelectedIndexForAcceptQuote(null);
    } else {
      setMainIndex(index);
      setSelectedCarrierIndex(carrier_index);
      setSelectedIndexForAcceptQuote(index);
    }
  };

  // Request Accept API
  const acceptRequeset = (id, carrierID, price) => {
    const payload = {
      type: isRoleStaff ? "staff" : "user",
      Status: "Approved",
      qoutesId: id,
      ChooseCarrier: [
        {
          carrierId: carrierID,
        },
      ],
      price: price,
      carrier_id: carrierID,
    };
    setAcceptQuoteLoading(true);
    staff_AcceptProposed(payload)
      .then(async (res) => {
        if (res.status) {
          await fetchQuoteList(false);

          setSelectedIndexForAcceptQuote(null);
          toast("Quote accepted, Your order has been placed.");
        } else {
          toast(res.msg);
        }
        setAcceptQuoteLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const decline_quotation_fun = async (quotation_id, carrier_id) => {
    try {
      const payload = {
        carrier_id: carrier_id,
        user_id: userId,
        quote_id: quotation_id,
      };

      setDeclineQuoteLoading(true);
      const res = await decline_quote_by_user(payload);
      if (res.status === 1) {
        await fetchQuoteList(false);
        setDeclineQuoteLoading(false);

        setSelectedIndexForAcceptQuote(null);
        toast("Declined successfully.");
      }
    } catch (err) {
      consoleService("error in decline_quotation_fun = ", err);
    }
  };

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => {
                      handleSearch(text.target.value);
                      setSearchQuery(text.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>
          <FilterSidebar
            close={handleToggle}
            handleFilterFun={handleSideFilter}
            type={"1"}
          />
          <Container fluid className=" pt-100">
            <div className="quotes_div">
              <Row className="align-items-center mb-3">
                <Col lg={6} xs={6}>
                  <Button
                    onClick={() => setModalShow(true)}
                    variant="primary"
                    className="btn-primaryAll btnPrimary me-2"
                  >
                    <FiPlusCircle /> New quotes
                  </Button>
                  {userRole !== "User" && (
                    <Button
                      onClick={() => setDirectShow(true)}
                      variant="primary"
                      className="btn-primaryAll btnPrimaryoutline btnPrimaryoutline-Hover"
                    >
                      <FiPlusCircle /> Direct request
                    </Button>
                  )}
                </Col>
                <Col lg={6} xs={6} className="text-end">
                  <Link onClick={handleToggle} className="filter_btn">
                    <Image src={FilterIcon} width={20} alt="" /> Filters
                  </Link>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col sm={12}>
                  <div className="react_table">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Loading</th>
                          <th>Distance</th>
                          <th>Eta</th>
                          <th>Vehicle</th>
                          <th style={{ width: "340px", minWidth: "340px" }}>
                            Carriers
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {quoteListData.length === 0 && (
                        <tr>
                          <td colSpan={9} className="text-center  pt-3 ">
                            No quotes
                          </td>
                        </tr>
                      )}

                      <tbody>
                        {displayedQuoteList &&
                          List_data.result.map((item, index) => {
                            var distanceNumber = Number(item.distance);
                            if (!isNaN(distanceNumber)) {
                              var distanceWithTwoDecimalPlaces =
                                distanceNumber.toFixed(0) + " km ";
                            } else {
                              console.log(
                                "Error: item.distance is not a valid number."
                              );
                            }
                            const isDirectRequest = item.directRequest == "yes";
                            const isFoundChooseCarrier =
                              item.ChooseCarrier.length > 0;
                            const loadingTime =
                              item.LOADING_INFORMATIONS &&
                              item.LOADING_INFORMATIONS[0].Hours;
                            const loadingConvertedTime = item
                              .LOADING_INFORMATIONS[0].Hours
                              ? loadingTime.replace(":", "h")
                              : "";

                            const deliveryTime =
                              item.DELIVERY_INFORMATIONS[0] &&
                              item.DELIVERY_INFORMATIONS[0].delivery_Hour;

                            const deliveryConvertedTime = item
                              .DELIVERY_INFORMATIONS[0].delivery_Hour
                              ? deliveryTime.replace(":", "h")
                              : "";

                            const state =
                              item.LOADING_INFORMATIONS &&
                              item.LOADING_INFORMATIONS[0].loadingCity
                                ? item.LOADING_INFORMATIONS[0].loadingCity
                                : item.LOADING_INFORMATIONS[0].loadingState;

                            const country =
                              item.LOADING_INFORMATIONS &&
                              item.LOADING_INFORMATIONS[0].loadingCountry &&
                              item.LOADING_INFORMATIONS[0].loadingCountry;

                            const delivery_state =
                              item.DELIVERY_INFORMATIONS &&
                              item.DELIVERY_INFORMATIONS[0].deliveryCity
                                ? item.DELIVERY_INFORMATIONS[0].deliveryCity
                                : item.DELIVERY_INFORMATIONS[0].deliveryState;

                            const delivery_country =
                              item.DELIVERY_INFORMATIONS &&
                              item.DELIVERY_INFORMATIONS[0].deliveryCountry &&
                              item.DELIVERY_INFORMATIONS[0].deliveryCountry;

                            const showLine = isDirectRequest
                              ? isFoundChooseCarrier
                                ? true
                                : false
                              : true;
                            return (
                              <>
                                {showLine && item.deleteStatus === "0" && (
                                  <tr key={index}>
                                    <td>{calculateCount(index)}</td>
                                    <td>
                                      <h4 className="name_text mb-1">
                                        {state}
                                        {state && country && "-"}
                                        {country}
                                      </h4>
                                      <h4 className="datetime_text">
                                        {moment(
                                          item.LOADING_INFORMATIONS &&
                                            item.LOADING_INFORMATIONS[0].Date
                                        ).format("DD.MM.YYYY")}{" "}
                                        - {loadingConvertedTime}
                                      </h4>
                                    </td>
                                    <td>
                                      <h4 className="name_text mb-1">
                                        {distanceWithTwoDecimalPlaces}
                                      </h4>
                                      <Image
                                        className="tableArrow_img"
                                        src={tablearrow}
                                        alt=""
                                      />
                                    </td>
                                    <td>
                                      <h4 className="name_text mb-1">
                                        {delivery_state}
                                        {delivery_state &&
                                          delivery_country &&
                                          "-"}
                                        {delivery_country}
                                      </h4>
                                      <h4 className="datetime_text">
                                        {moment(
                                          item.DELIVERY_INFORMATIONS[0]
                                            .delivery_Date
                                        ).format("DD.MM.YYYY")}
                                        {" - "}
                                        {deliveryConvertedTime}
                                      </h4>
                                    </td>
                                    <td>
                                      <h4 className="name_text">
                                        {item.category}
                                      </h4>
                                    </td>
                                    {/* CARRIER LIST */}
                                    <td>
                                      {item.ChooseCarrier.length > 0 &&
                                        item.ChooseCarrier.map(
                                          (carrier_item, carrier_index) => {
                                            const carrier_rating =
                                              carrier_item.carrier_ratings > 0
                                                ? carrier_item.carrier_ratings.toFixed(
                                                    1
                                                  )
                                                : 0;

                                            const complete_missions =
                                              carrier_item.complete_missions;
                                            const missions_txt =
                                              carrier_item.complete_missions > 1
                                                ? "missions"
                                                : "mission";
                                            const isShowAcceptToast =
                                              selected_carrier_index ==
                                                carrier_index &&
                                              mainIndex == index &&
                                              selectedIndexForAcceptQuote ==
                                                index;
                                            return (
                                              <>
                                                <OverlayTrigger
                                                  placement="top"
                                                  delay={{
                                                    show: 250,
                                                    hide: 400,
                                                  }}
                                                  overlay={
                                                    (carrier_item.Status ==
                                                      "Pending" &&
                                                      renderTooltip(
                                                        carrier_item
                                                      )) ||
                                                    (carrier_item.Status ==
                                                      "Rejected" &&
                                                      renderTooltip1(
                                                        carrier_item
                                                      )) ||
                                                    (carrier_item.Status ==
                                                      "Approved" &&
                                                      renderTooltip2(
                                                        carrier_item
                                                      ))
                                                  }
                                                >
                                                  <span
                                                    key={index}
                                                    className="table_badge"
                                                    ref={carrier_item}
                                                    onClick={() => {
                                                      if (
                                                        carrier_item.Status ==
                                                        "Approved"
                                                      ) {
                                                        handleCarrier(
                                                          isShowAcceptToast,
                                                          carrier_index,
                                                          index
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <div
                                                      ref={(Ref) => {
                                                        carrier_container_ref.current.set(
                                                          carrier_index,
                                                          Ref
                                                        );
                                                      }}
                                                    >
                                                      <Image
                                                        className="truckicon_img"
                                                        src={truckicon}
                                                        alt=""
                                                      />

                                                      {carrier_item.Status ===
                                                        "Pending" && (
                                                        <span className="badge_circle pending"></span>
                                                      )}
                                                      {carrier_item.Status ===
                                                        "Approved" && (
                                                        <span className="badge_circle active"></span>
                                                      )}
                                                      {carrier_item.Status ===
                                                        "Rejected" && (
                                                        <span className="badge_circle cancel"></span>
                                                      )}
                                                    </div>
                                                  </span>
                                                </OverlayTrigger>
                                                <Overlay
                                                  placement="bottom"
                                                  target={carrier_item.current}
                                                  show={isShowAcceptToast}
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id={`button-tooltip-${index}`}
                                                      className="toolTip newToolTip"
                                                      {...props}
                                                    >
                                                      <div
                                                        ref={(reference) => {
                                                          accept_quote_div_ref.current.set(
                                                            index,
                                                            reference
                                                          );
                                                        }}
                                                        className="carreirBox"
                                                      >
                                                        <div className="carrieBox">
                                                          <div className="iconBox">
                                                            <Image
                                                              src={
                                                                TruckWhiteIcon
                                                              }
                                                              alt=""
                                                              className="img-fluid"
                                                            />
                                                          </div>
                                                          <div className="car_right">
                                                            {/* {userRole !==
                                                              "User" && (
                                                              <h5>
                                                                {
                                                                  carrier_item.Company_name
                                                                }
                                                              </h5>
                                                            )} */}

                                                            <h5>
                                                              {userRole ==
                                                              "User"
                                                                ? carrier_item?.accountNumber
                                                                : carrier_item.Company_name}
                                                            </h5>

                                                            <div className="startFlex">
                                                              <ReactStarsRating
                                                                className={
                                                                  "Star_rating"
                                                                }
                                                                isHalf={true}
                                                                size={16}
                                                                starGap={0}
                                                                isEdit={false}
                                                                value={
                                                                  carrier_rating
                                                                }
                                                              />

                                                              <span>
                                                                {carrier_rating}
                                                                /5
                                                              </span>
                                                            </div>
                                                            <h5>
                                                              {
                                                                complete_missions
                                                              }{" "}
                                                              {missions_txt}
                                                            </h5>
                                                          </div>
                                                        </div>
                                                        <div className="carContent">
                                                          <h3>
                                                            PROPOSED PRICE
                                                          </h3>
                                                          <h4>
                                                            {/* {(Number(carrier_item.Price) + Number(carrier_item.margin)).toFixed(2)}{" "}
                                                            € */}
                                                            {userRole === "User"
                                                              ? `${(
                                                                  Number(
                                                                    carrier_item.Price
                                                                  ) +
                                                                  Number(
                                                                    carrier_item.margin
                                                                  )
                                                                ).toFixed(2)}`
                                                              : `${carrier_item.Price}`}{" "}
                                                            €
                                                          </h4>
                                                          <div className="actionBtn">
                                                            <Button
                                                              style={{
                                                                width: 100,
                                                                padding:
                                                                  decline_quote_loading
                                                                    ? 0
                                                                    : null,
                                                              }}
                                                              onClick={() =>
                                                                decline_quotation_fun(
                                                                  item._id,
                                                                  carrier_item.carrierId
                                                                )
                                                              }
                                                              disabled={
                                                                decline_quote_loading
                                                              }
                                                              variant="danger"
                                                              // className="ms-2"
                                                            >
                                                              {decline_quote_loading ? (
                                                                <Loader
                                                                  size={20}
                                                                />
                                                              ) : (
                                                                <>
                                                                  <FaRegTimesCircle />{" "}
                                                                  Decline
                                                                </>
                                                              )}
                                                            </Button>
                                                            <Button
                                                              style={{
                                                                width: 100,
                                                                padding:
                                                                  accept_quote_loading
                                                                    ? 0
                                                                    : null,
                                                              }}
                                                              onClick={() =>
                                                                acceptRequeset(
                                                                  item._id,
                                                                  carrier_item.carrierId,
                                                                  carrier_item.Price
                                                                )
                                                              }
                                                              disabled={
                                                                accept_quote_loading
                                                              }
                                                              variant="success"
                                                              className="ms-2"
                                                            >
                                                              {accept_quote_loading ? (
                                                                <Loader
                                                                  size={20}
                                                                />
                                                              ) : (
                                                                <>
                                                                  <FaRegCircleCheck />{" "}
                                                                  Accept
                                                                </>
                                                              )}
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              </>
                                            );
                                          }
                                        )}
                                    </td>

                                    {/* {item.directRequest === "yes" && (
                                      <td>
                                        <div className="carrier_name">
                                          <span className="carrier_badge">
                                            <Image
                                              className="truckicon_img"
                                              src={truckicon}
                                              alt=""
                                            />
                                          </span>
                                          <div className="carrier_content">
                                            <h5 className="carrier_text">
                                              {item.carrier}
                                            </h5>
                                            <h5 className="carrier_price">
                                              {item.prize}
                                            </h5>
                                          </div>
                                          <span className="waiting_text">
                                            <FiClock /> Waiting for confirmation
                                          </span>
                                        </div>
                                      </td>
                                    )} */}

                                    <td>
                                      <div className="mb-2">
                                        {userRole !== "Carrier" && (
                                          <>
                                            {["start"].map((direction) => (
                                              <DropdownButton
                                                as={ButtonGroup}
                                                key={direction}
                                                id={`dropdown-button-drop-${direction}`}
                                                drop={direction}
                                                className="tabledrop_btn"
                                                title={<FiMoreVertical />}
                                              >
                                                <>
                                                  {item.ChooseCarrier[0]
                                                    .Status === "Approved" ? (
                                                    ""
                                                  ) : (
                                                    <Dropdown.Item
                                                      eventKey="1"
                                                      className="menudrop_link"
                                                      onClick={() => {
                                                        setEditShowModal(true);
                                                        setEditId(item._id);
                                                      }}
                                                    >
                                                      <Image
                                                        className="editimg"
                                                        src={EditIcon}
                                                        alt=""
                                                      />{" "}
                                                      Modify
                                                    </Dropdown.Item>
                                                  )}
                                                </>

                                                <Dropdown.Item
                                                  eventKey="2"
                                                  className="menudrop_link"
                                                  onClick={() => {
                                                    setViewDetailModal(true);
                                                    setEditId(item._id);
                                                  }}
                                                >
                                                  <Image
                                                    className="editimg"
                                                    src={SearchIcon}
                                                    alt=""
                                                    width={20}
                                                  />{" "}
                                                  View
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                  eventKey="2"
                                                  className="menucancel_link"
                                                  onClick={() => {
                                                    handelDeleteClick(item._id);
                                                  }}
                                                >
                                                  <Image
                                                    className="editimgt"
                                                    src={TrashIcon}
                                                    alt=""
                                                  />{" "}
                                                  Delete
                                                </Dropdown.Item>
                                              </DropdownButton>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
            {/* pagination */}
            <div className="pagination-container">
              <div className="paginationFeature">
                <p>Rows per page: {list_row_limit}</p>
              </div>
              <div className="pagination">
                <p>
                  {List_data.from} - {List_data.to} of {List_data.total_docs}
                </p>
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={list_row_limit}
                  totalItemsCount={List_data.total_docs}
                  pageRangeDisplayed={list_row_limit}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination"
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
      {editShowModal === true && (
        <EditQouteModal
          show={editShowModal}
          editId={editId}
          onShowOtherModal={() => {
            setEditShowModal(true);
            setModalShow(false);
          }}
          onHide={() => {
            setModalShow(false);
            setEditShowModal(false);
          }}
        />
      )}

      {viewDetialModal === true && (
        <ViewQuoteDetails
          show={viewDetialModal}
          editId={editId}
          onShowOtherModal={() => {
            setViewDetailModal(true);
            setModalShow(false);
          }}
          onHide={() => {
            setModalShow(false);
            setViewDetailModal(false);
          }}
        />
      )}

      <Modal centered show={show_delete_modal}>
        <Modal.Body className="p-3">
          <div className="deleteModal text-center">
            {/* <XCircle className="deletemodal_icon" /> */}
            <h2>Are You Sure ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button
                className="btn-cancel me-1"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 95 }}
                className="btn-ok"
                onClick={() => {
                  delete_quote_fun();
                }}
                disabled={deleteQuoteLoading}
              >
                {deleteQuoteLoading ? <Loader /> : "OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <NewQouteModalOne
        show={modalShow}
        onShowOtherModal={() => {
          setModalShow1(true);
          setModalShow(false);
        }}
        close={() => {
          setModalShow(false);
          setModalShow1(false);
        }}
      />
      <NewQouteModalOneTwo
        show={modalShow1}
        close={() => {
          setModalShow(true);
          setModalShow1(false);
        }}
        onShowOtherModal={() => {
          setModalShow1(false);
          setModalShow(false);
          setModalShow2(true);
        }}
      />
      <NewQouteModalOneTHree
        show={modalShow2}
        close={() => {
          setModalShow(false);
          setModalShow1(true);
          setModalShow2(false);
        }}
        onShowOtherModal={() => {
          setModalShow1(false);
          setModalShow(false);
          setModalShow2(false);
        }}
      />

      <DirectRequetsModal
        show={directmodalShow}
        close={() => {
          setDirectShow(false);
        }}
      />
      <ToastContainer />
    </>
  );
};

// Direct Modal
function DirectRequetsModal(props) {
  const [activeTab, setActiveTab] = useState("");
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;

  const {
    _id,
    email,
    firstName,
    lastName,
    accountOnwerFirstName,
    accountOnwerLastName,
    profile,
  } = userDeatildata;
  const userRole = profile;
  const isRoleStaff = userRole == "Staff";
  const [carrierList, setCarrierList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const [reference, setReference] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [pickupLocation, setPickupLocation] = useState(false);
  const [loadingLatLong, setLoadingLatLong] = useState({});
  const [deliveryRefernce, setDeliveryRefernce] = useState("");
  const [deliveryCompanyName, setDeliveryCompanyName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryContactName, setDeliveryContactName] = useState("");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState("");
  const [deliveryContactEmail, setDeliveryContactEmail] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryLatLong, setDeliveryLatLong] = useState({});
  const [materialName, setMaterialName] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  // address
  const [loadingState, setLoadingState] = useState("");
  const [loadingCity, setLoadingCity] = useState("");
  const [loadingCountry, setLoadingCountry] = useState("");
  const [deliveryLoadingState, setDeliveryLoadingState] = useState("");
  const [deliveryLoadingCity, setDeliveryLoadingCity] = useState("");
  const [deliveryLoadingCountry, setDeliveryLoadingCountry] = useState("");
  // general
  const [numberOfPallets, setNumberOfPallets] = useState("");
  const [sizeOfPallets, setSizeOfPallets] = useState("");
  const [heightOfPallets, setHeightOfPallets] = useState("");
  const [totalWeightOfPallets, setTotalWeightOfPallets] = useState("");
  // tanker
  const [adrReference, setAdrReference] = useState("");
  const [quantity, setQuantity] = useState("");
  // refigrater
  const [temprature, setTemperature] = useState("");
  // Error State
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [referenceError, setReferenceError] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [dateError, setDateError] = useState(0);
  const [timeError, setTimeError] = useState(0);
  const [contactNameError, setContactNameError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);

  const [deliveryRefernceError, setDeliveryRefernceError] = useState(0);
  const [deliveryCompanyNameError, setDeliveryCompanyNameError] = useState(0);
  const [deliveryAddressError, setDeliveryAddressError] = useState(0);
  const [deliveryDateError, setDeliveryDateError] = useState(0);
  const [deliveryTimeError, setDeliveryTimeError] = useState(0);
  const [deliveryContactNameError, setDeliveryContactNameError] = useState(0);
  const [deliveryContactPhoneError, setDeliveryContactPhoneError] = useState(0);
  const [deliveryContactEmailError, setDeliveryContactEmailError] = useState(0);
  const [deliveryTypeError, setDeliveryTypeError] = useState(0);

  // general
  const [numberOfPalletsError, setNumberOfPalletsError] = useState(0);
  const [sizeOfPalletsError, setSizeOfPalletsError] = useState(0);
  const [heightOfPalletsError, setHeightOfPalletsError] = useState(0);
  const [totalWeightOfPalletsError, setTotalWeightOfPalletsError] = useState(0);

  // tanker
  const [adrReferenceError, setAdrReferenceError] = useState(0);
  const [quantityError, setQuantityError] = useState(0);
  // refigrater
  const [tempratureError, setTemperatureError] = useState(0);

  const [carrierName, setCarrierName] = useState("");
  const [clientName, setClientName] = useState("");
  const [carrierEmail, setCarrierEmail] = useState("");
  const [selectedCarrierID, setSelectedCarrierID] = useState("");
  const [selectedClientID, setSelectedClientID] = useState("");

  const [msds, setMsds] = useState("Yes");

  const [carrierNameError, setCarrierNameError] = useState("");
  const [carrierEmailError, setCarrierEmailError] = useState("");
  const [msdsError, setMsdsError] = useState("");
  const [activeTabError, setActiveTabError] = useState("");
  const [selectedCarrierIDError, setSelectedCarrierIDError] = useState(0);

  const { setIsLoading } = useContext(LoadingContext);
  const [direct_quote_submit_loading, setDirectQuoteSubmitLoading] =
    useState(false);
  const [addMaterialModal, setAddMaterialModal] = useState(false);
  const [saveMaterailModal, setSaveMaterialModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [editMaterialId, setEditMaterialId] = useState(null);

  const [tankerAddMaterialModal, setTankerAddMaterialModal] = useState(false);
  const [tankerSaveMaterailModal, setTankerSaveMaterailModal] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [savedMaterials, setSavedMaterials] = useState([]);
  const [tankerEditItemId, setTankerEditItemId] = useState(null);

  const [refrigeratedAddMaterialModal, setRefrigeratedAddMaterialModal] =
    useState(false);
  const [refrigeratedSaveMaterailModal, setRefrigeratedSaveMaterialModal] =
    useState(false);
  const [selected_field_arr, setSelected_arr] = useState([]);
  const [preselected_Item, setpreselectedItem] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  // refigrater
  const [refrigeratedMaterials, setRefrigeratedMaterials] = useState([]);

  let count = 0;

  // General cargo
  const handleSave = () => {
    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      !totalWeightOfPallets
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedMaterials = materials.map((material) =>
        material.id === editMaterialId
          ? {
              ...material,
              materialName,
              numberOfPallets,
              sizeOfPallets,
              heightOfPallets,
              totalWeightOfPallets,
            }
          : material
      );
      setMaterials(updatedMaterials);
      setEditMaterialId(null);
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
      };
      setMaterials([...materials, newMaterial]);
    }
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setAddMaterialModal(false);
    setSaveMaterialModal(true);
  };

  const handleTimeChange = (newValue) => {
    // setTime(newValue);
    const formattedTime = moment(newValue.$d).format("HH:mm");
    setTime(formattedTime);
    console.log("Selected Time:", formattedTime);
  };

  const handledeliveryTimeChange = (newValue) => {
    // setTime(newValue);
    const formattedTime = moment(newValue.$d).format("HH:mm");
    setDeliveryTime(formattedTime);
    console.log("deliveryTime Time:", formattedTime);
  };

  const handleDelete = (id) => {
    const updatedMaterials = materials.filter((material) => material.id !== id);
    setMaterials(updatedMaterials);
  };
  const handleEdit = (id) => {
    setEditMaterialId(id);
    const materialToEdit = materials.find((material) => material.id === id);
    if (materialToEdit) {
      setMaterialName(materialToEdit.materialName);
      setNumberOfPallets(materialToEdit.numberOfPallets);
      setSizeOfPallets(materialToEdit.sizeOfPallets);
      setHeightOfPallets(materialToEdit.heightOfPallets);
      setTotalWeightOfPallets(materialToEdit.totalWeightOfPallets);
    }
    setAddMaterialModal(true);
  };
  // Tanker function
  const handleCheckbox = (option, edit) => {
    if (edit) {
      if (preselected_Item.includes(option)) {
        const filteredArr = preselected_Item.filter((item) => item !== option);
        setpreselectedItem([...filteredArr]);
      } else {
        setpreselectedItem([...preselected_Item, option]);
      }
    } else {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        setSelected_arr([...selectedOptions, option]);

        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const handleDeleteSavedMaterial = (id) => {
    const updatedSavedMaterials = savedMaterials.filter(
      (material) => material.id !== id
    );
    setSavedMaterials(updatedSavedMaterials);
  };

  const handleEditSavedMaterial = (id, item) => {
    setIsEdit(true);
    setTankerEditItemId(id);
    setSelected_arr(item);
    const materialToEdit = savedMaterials.find(
      (material) => material.id === id
    );
    if (materialToEdit) {
      setpreselectedItem(materialToEdit.selectedOptions);
      setMaterialName(materialToEdit.materialName);
      setAdrRadio(materialToEdit.adr == "Yes" ? "yes" : "no");
      setAdrReference(materialToEdit.adrReference);
      setQuantity(materialToEdit.quantity);
      setSelectOptions(materialToEdit.selectedOptions);
    }
    setTankerAddMaterialModal(true);
  };

  const handleSaveMaterial = () => {
    if (isEdit === false) {
      if (!materialName || !quantity || selectedOptions.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    } else {
      if (!materialName || !quantity || preselected_Item.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    }

    if (tankerEditItemId !== null) {
      const updatedSavedMaterials = savedMaterials.map((material) =>
        material.id === tankerEditItemId
          ? {
              ...material,
              materialName,
              adr: radio === "yes" ? "Yes" : "No",
              adrReference: radio ? adrReference : "",
              quantity: quantity,
              selectedOptions: preselected_Item,
            }
          : material
      );
      setSavedMaterials(updatedSavedMaterials);
      setIsEdit(false);

      setMaterialName("");
      setAdrRadio("");
      setAdrReference("");
      setQuantity("");
      setSelectedOptions([]);
      setTankerEditItemId(null);
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        adr: radio == "yes" ? "Yes" : "No",
        adrReference: radio == "yes" ? adrReference : "",
        quantity: quantity,
        selectedOptions: selectedOptions,
      };

      setSavedMaterials([...savedMaterials, newMaterial]);
      setAdrRadio("");
      setMaterialName("");
      setQuantity("");
      setAdrReference("");
      setSelectedOptions([]);
    }

    setTankerAddMaterialModal(false);
    setTankerSaveMaterailModal(true);
  };

  // refrigerated
  const handleRefrigeratedSave = () => {
    setRefrigeratedAddMaterialModal(true);
    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      !totalWeightOfPallets ||
      !temprature
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedRefrigeratedMaterials = refrigeratedMaterials.map(
        (material) =>
          material.id === editMaterialId
            ? {
                ...material,
                materialName,
                numberOfPallets,
                sizeOfPallets,
                heightOfPallets,
                totalWeightOfPallets,
                temprature,
                msds,
              }
            : material
      );
      setRefrigeratedMaterials(updatedRefrigeratedMaterials);
      setEditMaterialId(null);
    } else {
      const newRefrigeratedMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
        temprature,
        msds,
      };
      setRefrigeratedMaterials([
        ...refrigeratedMaterials,
        newRefrigeratedMaterial,
      ]);
    }
    // Reset input states and modals
    resetInputStates();
    setRefrigeratedAddMaterialModal(false);
    setRefrigeratedSaveMaterialModal(true);
  };

  const handleRefrigeratedDelete = (id) => {
    const updatedRefrigeratedMaterials = refrigeratedMaterials.filter(
      (material) => material.id !== id
    );
    setRefrigeratedMaterials(updatedRefrigeratedMaterials);
  };

  const handleRefrigeratedEdit = (id) => {
    setEditMaterialId(id);
    const refrigeratedMaterialToEdit = refrigeratedMaterials.find(
      (material) => material.id === id
    );
    if (refrigeratedMaterialToEdit) {
      setInputStates(refrigeratedMaterialToEdit);
    }
    setRefrigeratedAddMaterialModal(true);
  };

  const resetInputStates = () => {
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
  };

  const setInputStates = (material) => {
    setMaterialName(material.materialName);
    setNumberOfPallets(material.numberOfPallets);
    setSizeOfPallets(material.sizeOfPallets);
    setHeightOfPallets(material.heightOfPallets);
    setTotalWeightOfPallets(material.totalWeightOfPallets);
  };

  // API Implimentatoin
  useEffect(() => {
    onProfileValidation();
  }, [
    reference,
    companyName,
    address,
    date,
    time,
    contactName,
    contactPhone,
    contactEmail,
    deliveryRefernce,
    deliveryCompanyName,
    deliveryAddress,
    deliveryDate,
    deliveryTime,
    deliveryContactName,
    deliveryContactPhone,
    deliveryContactEmail,
    numberOfPallets,
    sizeOfPallets,
    heightOfPallets,
    totalWeightOfPallets,
    adrReference,
    quantity,
    temprature,
    otherInformation,
    activeTab,
  ]);

  const onCancelClick = (props) => {
    setActiveTab("");
    setMaterials([]);
    setCarrierEmail("");
    setCarrierName("");
    setAddress("");
    setDeliveryAddress("");
    setSubmit(false);
    props.close();
  };

  const onSubmit = (props) => {
    setSubmit(true);
    onProfileValidation();

    if (errorCount > 0) {
      console.log("errorCount if  ", errorCount);
      return;
    } else {
      addStaffQuote(props);
    }
  };

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!activeTab) {
      errorCount++;
      let msg = "Please select a category.";
      setActiveTabError(msg);
    } else {
      setActiveTabError(null);
    }
    // if (!reference) {
    //   errorCount++;
    //   let msg = "Please enter reference.";
    //   setReferenceError(msg);
    // } else {
    //   setReferenceError(null);
    // }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }
    if (!date) {
      errorCount++;
      let msg = "Please enter date.";
      setDateError(msg);
    } else {
      setDateError(null);
    }

    if (!contactName) {
      errorCount++;
      let msg = "Please enter contact name.";
      setContactNameError(msg);
    } else {
      setContactNameError(null);
    }
    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!deliveryType) {
      errorCount++;
      let msg = "Please select delivery type.";
      setDeliveryTypeError(msg);
    } else {
      setDeliveryTypeError(null);
    }

    // if (!deliveryRefernce) {
    //   errorCount++;
    //   let msg = "Please enter delivery reference.";
    //   setDeliveryRefernceError(msg);
    // } else {
    //   setDeliveryRefernceError(null);
    // }

    if (!deliveryAddress) {
      errorCount++;
      let msg = "Please enter delivery address.";
      setDeliveryAddressError(msg);
    } else {
      setDeliveryAddressError(null);
    }
    if (!deliveryDate) {
      errorCount++;
      let msg = "Please enter delivery date.";
      setDeliveryDateError(msg);
    } else {
      setDeliveryDateError(null);
    }

    if (!deliveryContactName) {
      errorCount++;
      let msg = "Please enter delivery contact name.";
      setDeliveryContactNameError(msg);
    } else {
      setDeliveryContactNameError(null);
    }

    if (!deliveryContactPhone) {
      errorCount++;
      let msg = "Please enter a vlaid number.";
      setDeliveryContactPhoneError(msg);
    } else {
      setDeliveryContactPhoneError(null);
    }

    let deliveryContactEmailError = SERVICE.validateEmail(deliveryContactEmail);
    if (deliveryContactEmailError !== undefined) {
      errorCount++;
      let msg =
        deliveryContactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setDeliveryContactEmailError(msg);
    } else {
      setDeliveryContactEmailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  // Staff api
  const addStaffQuote = (item) => {
    let materialInformation;
    if (activeTab === "General cargo") {
      materialInformation = materials;
    } else if (activeTab === "Tanker") {
      materialInformation = savedMaterials;
    } else if (activeTab === "Refrigerated") {
      materialInformation = refrigeratedMaterials;
    }
    const applicant_name = isRoleStaff
      ? `${firstName} ${lastName}`
      : `${accountOnwerFirstName} ${accountOnwerLastName}`;
    const payload = {
      StaffId: _id,
      userId: _id,
      category: activeTab,
      MaterialInformation: materialInformation,
      OtherInformation: otherInformation,
      LOADING_INFORMATIONS: [
        {
          Reference: reference,
          Company_name: companyName,
          Address: {
            type: "Point",
            coordinates: [loadingLatLong.lng, loadingLatLong.lat],
          },
          Date: date,
          Hours: time,
          Contact_name: contactName,
          Contact_phone: contactPhone,
          Contact_email: contactEmail,
          appoitment_location: pickupLocation,
          city: address,
          loadingcode: selectedCountryCode,
          loadingState: loadingState,
          loadingCity: loadingCity,
          loadingCountry: loadingCountry,
        },
      ],
      DELIVERY_INFORMATIONS: [
        {
          appoitment_location_delivery: deliveryLocation,
          Delivery_type: deliveryType,
          Reference: deliveryRefernce,
          Company_name: deliveryCompanyName,
          Address: {
            type: "Point",
            coordinates: [deliveryLatLong.lng, deliveryLatLong.lat],
          },
          delivery_Date: deliveryDate,
          delivery_Hour: deliveryTime,
          deliver_Contact_name: deliveryContactName,
          deliver_contact_phone: deliveryContactPhone,
          delivery_conntact_email: deliveryContactEmail,
          delivery_city: deliveryAddress,
          delvieryCode: unLoadingCountryCode,
          deliveryState: deliveryLoadingState,
          deliveryCity: deliveryLoadingCity,
          deliveryCountry: deliveryLoadingCountry,
        },
      ],
      ChooseCarrier: selectedCarrierID ? [selectedCarrierID] : [],
      carrier_email: "",
      direct_email: carrierEmail,
      carrier: carrierName,
      directRequests: "yes",
      Latitude: "31.2980035714754",
      Longitudes: "75.56183075264784",
      loading_Latitude: loadingLatLong.lat,
      loading_Longitudes: loadingLatLong.lng,
      delevry_Latitude: deliveryLatLong.lat,
      delevry_Longitudes: deliveryLatLong.lng,
      prize: "",
      applicant: applicant_name,
      type: isRoleStaff ? "staff" : "user",
      client_id: selectedClientID,
    };

    setDirectQuoteSubmitLoading(true);
    add_Quote(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Quotes request sent successfully.");
          setTimeout(() => {
            item.close(false);
            setActiveTab("");
            setCarrierEmail("");
            setCarrierName("");
            setAddress("");
            setDeliveryAddress("");
            setContactPhone("");
            setDeliveryContactPhone("");
            setMaterials([]);
            setSavedMaterials([]);
            setRefrigeratedMaterials([]);
            setSubmit(false);
            setSelectedCarrierID("");
          }, 1500);
        } else toast(res.msg);
        setDirectQuoteSubmitLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handeleDeliveryType = (type) => {
    setDeliveryType(type);
  };

  const handleMSDS = (type) => {
    setMsds(type);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [radio, setAdrRadio] = useState("yes");

  const handleRadio = (type) => {
    setAdrRadio(type);
  };

  const fetchStaffCarrierList = () => {
    const payload = {};
    setIsLoading(true);
    staff_GiveCarrierList(payload)
      .then((res) => {
        const filteredCarrierList = res.result.CarrierList.filter((carrier) =>
          carrier.companyName.toLowerCase().includes(carrierName.toLowerCase())
        );
        setCarrierList(filteredCarrierList);
        setIsLoading(false);
        setShowList(true);
      })
      .catch((err) => {
        console.log("err in addStaffQuote = ", err);
        setIsLoading(false);
      });
  };

  const handleSelectCarrier = (carrierName) => {
    setSelectedCarrierID(carrierName._id);
    setCarrierName(carrierName.companyName);
    setShowList(false);
  };

  const fetchStaffClientList = (carrierName) => {
    const payload = {};
    staff_ClientList(payload)
      .then((res) => {
        const filteredCarrierList = res.result.clientList.filter((carrier) => {
          return (
            carrier.companyName
              .toLowerCase()
              .includes(carrierName.toLowerCase()) ||
            carrier.accountNumber.includes(carrierName)
          );
        });
        setClientList(filteredCarrierList);
        setShowClientList(true);
      })
      .catch((err) => {
        console.log("err in addStaffQuote = ", err);
      });
  };

  const handleSelectClient = (carrierName) => {
    setSelectedClientID(carrierName._id);
    setClientName(carrierName.companyName);
    setShowClientList(false);
  };

  useEffect(() => {
    if (carrierName !== "") {
      const selectedCarrier = carrierList.find(
        (carrier) => carrier.companyName === carrierName
      );

      // if (selectedCarrier) {
      //   setCarrierEmail(selectedCarrier.contactEmail);
      // } else {
      //   setCarrierEmail("");
      // }
    } else {
      setCarrierEmail("");
    }
  }, [carrierName, carrierList]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelect = async (value, placeId) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    let state = "";
    let city = "";
    let country = "";
    let countryShort = "";
    for (const component of results[0].address_components) {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }
    setLoadingLatLong(latLng);
    setAddress(value + " " + postalCode);
    setLoadingState(state);
    setLoadingCity(city);
    setLoadingCountry(countryShort);
  };

  const handleSelectDelivery = async (value, placeId) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};

    let state = "";
    let city = "";
    let country = "";
    let countryShort = "";

    for (const component of results[0].address_components) {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }

    setDeliveryLatLong(latLng);
    setDeliveryAddress(value + " " + postalCode);

    setDeliveryLoadingState(state);
    setDeliveryLoadingCity(city);
    setDeliveryLoadingCountry(countryShort);
  };

  // Phone code
  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Direct request</h2>
        <BreadCrumbHeader image={TruckIcon} title="CLIENT" />
        {userRole === "Staff" && (
          <div className="mb-3 searchORbox">
            <div className="inputBox">
              <h4 className="modal_label">
                Search a client <span className="requiresLable">*</span>
              </h4>
              <TextInputWithLabel
                placeholder="Enter a name or ID"
                type="text"
                value={clientName}
                onChange={(event) => {
                  setClientName(event.target.value);
                  fetchStaffClientList(event.target.value);
                }}
              />
              {selectedCarrierIDError && Submit ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                    marginTop: -13,
                  }}
                >
                  {selectedCarrierIDError}
                </div>
              ) : null}
              <IoIosArrowDropdown
                className="dropdownfilter"
                onClick={() => {
                  showClientList
                    ? setShowClientList(false)
                    : fetchStaffClientList(clientName);
                }}
              />
            </div>
            {showClientList && (
              <div className="serachResult">
                <ul>
                  {clientList &&
                    clientList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            handleSelectClient(item);
                          }}
                        >
                          {item.companyName}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        )}
        <BreadCrumbHeader image={UserInfoIcon} title="CARRIER INFORMATIONS" />
        <div className="mb-3 searchORbox ">
          <div className="inputBox">
            <TextInputWithLabel
              label="Search a carrier"
              placeholder="Enter a name or ID"
              type="text"
              value={carrierName}
              onChange={(event) => {
                setCarrierName(event.target.value);
                fetchStaffCarrierList();
              }}
            />

            <IoIosArrowDropdown
              className="dropdownfilter"
              onClick={() => {
                showList ? setShowList(false) : fetchStaffCarrierList();
              }}
            />
          </div>

          {showList && (
            <div className="serachResult">
              <ul>
                {carrierList &&
                  carrierList.map((item, index) => {
                    return (
                      <li key={index} onClick={() => handleSelectCarrier(item)}>
                        {item.companyName}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

          <span className="se_or">OR</span>
          <TextInputWithLabel
            label="Contact a carrier by email"
            placeholder="Enter an email"
            type="text"
            value={carrierEmail}
            onChange={(text) => setCarrierEmail(text.target.value)}
          />
        </div>

        <BreadCrumbHeader image={TruckIcon} title="VEHICLE CATEGORY" />
        <h4 className="modal_label">
          Select a category <span className="requiresLable">*</span>
        </h4>
        <div className="tab-buttons">
          <Button
            onClick={() => handleTabClick("General cargo")}
            className={activeTab === "General cargo" ? "active" : ""}
          >
            General cargo
          </Button>
          <Button
            onClick={() => handleTabClick("Tanker")}
            className={activeTab === "Tanker" ? "active" : ""}
          >
            Tanker
          </Button>
          <Button
            onClick={() => handleTabClick("Refrigerated")}
            className={activeTab === "Refrigerated" ? "active" : ""}
          >
            Refrigerated
          </Button>
          {activeTabError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {activeTabError}
            </div>
          ) : null}
        </div>
        {/* General cargo */}
        <div className="tab-content">
          {activeTab === "General cargo" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />
              {/* material detial  */}
              {saveMaterailModal && (
                <div className="modiFy">
                  {materials &&
                    materials.map((material) => (
                      <div key={material.id}>
                        <div className="mod-flex">
                          <span className="numberText">{++count}</span>
                          <Link
                            className="me-3 edlink"
                            onClick={() => handleEdit(material.id)}
                          >
                            <Image className="editimg" src={EditIcon} alt="" />
                          </Link>
                          <Link
                            className="edlink"
                            onClick={() => handleDelete(material.id)}
                          >
                            <Image
                              className="editimgt edlink"
                              src={TrashIcon}
                              alt=""
                            />
                          </Link>
                        </div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Number of pallets</th>
                              <th>Size of pallets</th>
                              <th>Height of pallets</th>
                              <th>Weight of pallets</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{material.materialName}</td>
                              <td>{material.numberOfPallets}</td>
                              <td>{material.sizeOfPallets}</td>
                              <td>{material.heightOfPallets}</td>
                              <td>{material.totalWeightOfPallets}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    ))}
                </div>
              )}
              {/* Add Material View */}
              {addMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">{++count}</span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>

                  <div className="inputFlex">
                    <div>
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Number of pallets"
                        reqstart="*"
                        value={numberOfPallets}
                        onChange={(text) =>
                          setNumberOfPallets(text.target.value)
                        }
                      />
                      {numberOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {numberOfPalletsError}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Size of pallets"
                        reqstart="*"
                        value={sizeOfPallets}
                        onChange={(text) => setSizeOfPallets(text.target.value)}
                      />
                      {sizeOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {sizeOfPalletsError}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Height of pallets"
                        value={heightOfPallets}
                        onChange={(text) =>
                          setHeightOfPallets(text.target.value)
                        }
                      />
                      {heightOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {heightOfPalletsError}
                        </div>
                      ) : null}
                    </div>

                    <div className="kgflex">
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Total weight"
                        reqstart="*"
                        value={totalWeightOfPallets}
                        onChange={(text) =>
                          setTotalWeightOfPallets(text.target.value)
                        }
                      />
                      {totalWeightOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {totalWeightOfPalletsError}
                        </div>
                      ) : null}
                      <span>Kg</span>
                    </div>
                  </div>

                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              )}
              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() => setAddMaterialModal(!addMaterialModal)}
              >
                + Add material
              </Button>
              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}

          {/* Tanker */}
          {activeTab === "Tanker" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />
              {/* Tanker material detial  */}
              {tankerSaveMaterailModal && (
                <div className="modiFy">
                  {savedMaterials.map((material) => (
                    <div key={material.id}>
                      <div className="mod-flex">
                        <span className="numberText">{++count}</span>
                        <Link
                          className="me-3 edlink"
                          onClick={() =>
                            handleEditSavedMaterial(
                              material.id,
                              material.selectedOptions
                            )
                          }
                        >
                          <Image className="editimg" src={EditIcon} alt="" />
                        </Link>
                        <Link
                          className="edlink"
                          onClick={() => handleDeleteSavedMaterial(material.id)}
                        >
                          <Image
                            className="editimgt edlink"
                            src={TrashIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th style={{ minWidth: 350, maxWidth: 350 }}>
                              Material
                            </th>
                            <th style={{ minWidth: 80 }}>ADR</th>
                            <th>Quantity</th>
                            <th style={{ minWidth: 80 }}>MSDS included</th>
                            <th style={{ minWidth: 80 }}>Blacklisted</th>
                            <th style={{ minWidth: 80 }}>
                              Cleaning certificate
                            </th>
                            <th style={{ minWidth: 80 }}>Pump needed</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                minWidth: 350,
                                maxWidth: 350,
                                paddingRight: 5,
                              }}
                            >
                              {material.materialName}
                            </td>
                            <td>
                              {material && material.adr == "Yes"
                                ? `Yes - ${material.adrReference}`
                                : "No"}
                            </td>
                            <td>{material.quantity} L </td>
                            <td>
                              {material.selectedOptions &&
                              material.selectedOptions.includes("MSDS included")
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                              material.selectedOptions.includes("Blacklisted")
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                              material.selectedOptions.includes(
                                "Cleaning certificate"
                              )
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                              material.selectedOptions.includes("Pump needed")
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              )}

              {tankerAddMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">
                      {tankerEditItemId ? tankerEditItemId : ++count}
                    </span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>
                  <div className="custom_radio">
                    <h5>
                      ADR <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="radio"
                        name="ADR"
                        className="card-input-element"
                        checked={radio == "yes" && true}
                        onChange={() => handleRadio("yes")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">Yes</div>
                      </div>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="ADR"
                        checked={radio == "no" && true}
                        className="card-input-element"
                        onChange={() => handleRadio("no")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">No</div>
                      </div>
                    </label>
                  </div>

                  {radio == "yes" && (
                    <Row>
                      <Col lg={4} md={6}>
                        <TextInputWithLabel
                          name="text"
                          placeholder="Enter ADR Reference"
                          label="ADR Reference"
                          reqstart="*"
                          value={adrReference}
                          onChange={(text) =>
                            setAdrReference(text.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col lg={4} md={6}>
                      <div className="d-flex align-items-center ">
                        <TextInputWithLabel
                          name="text"
                          placeholder="0"
                          label="Quantity"
                          reqstart="*"
                          value={quantity}
                          onChange={(text) => setQuantity(text.target.value)}
                        />
                        <span className="ms-2 mt-2">L</span>
                      </div>
                    </Col>
                  </Row>

                  <div className="custom_radio">
                    <h5>
                      Select options <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("MSDS included", isEdit)}
                      />

                      <div
                        className={`card-input ${
                          isEdit === true
                            ? preselected_Item.some(
                                (item) => item == "MSDS included"
                              )
                              ? "tanker_selection_opt_bg_color"
                              : "tanker_selection_opt_bg_color_alternate2"
                            : selectOptions.some(
                                (item) => item == "MSDS included"
                              ) && "tanker_selection_opt_bg_color"
                        }`}
                      >
                        <div className="panel-heading">MSDS included</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("Blacklisted", isEdit)}
                      />

                      <div
                        className={`card-input ${
                          isEdit === true
                            ? preselected_Item.some(
                                (item) => item == "Blacklisted"
                              )
                              ? "tanker_selection_opt_bg_color"
                              : "tanker_selection_opt_bg_color_alternate2"
                            : selectOptions.some(
                                (item) => item == "Blacklisted"
                              ) && "tanker_selection_opt_bg_color"
                        }`}
                      >
                        <div className="panel-heading">Blacklisted</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() =>
                          handleCheckbox("Cleaning certificate", isEdit)
                        }
                      />
                      <div
                        className={`card-input ${
                          isEdit === true
                            ? preselected_Item.some(
                                (item) => item == "Cleaning certificate"
                              )
                              ? "tanker_selection_opt_bg_color"
                              : "tanker_selection_opt_bg_color_alternate2"
                            : selectOptions.some(
                                (item) => item == "Cleaning certificate"
                              ) && "tanker_selection_opt_bg_color"
                        }`}
                      >
                        <div className="panel-heading">
                          Cleaning certificate
                        </div>
                      </div>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("Pump needed", isEdit)}
                      />
                      <div
                        className={`card-input ${
                          isEdit === true
                            ? preselected_Item.some(
                                (item) => item == "Pump needed"
                              )
                              ? "tanker_selection_opt_bg_color"
                              : "tanker_selection_opt_bg_color_alternate2"
                            : selectOptions.some(
                                (item) => item == "Pump needed"
                              ) && "tanker_selection_opt_bg_color"
                        }`}
                      >
                        <div className="panel-heading">Pump needed</div>
                      </div>
                    </label>
                  </div>

                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={() => handleSaveMaterial()}
                  >
                    Save
                  </Button>
                </div>
              )}

              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() =>
                  setTankerAddMaterialModal(!tankerAddMaterialModal)
                }
              >
                + Add material
              </Button>

              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                value={otherInformation}
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}

          {/* Refrigerated */}
          {activeTab === "Refrigerated" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />

              {/* Refrigerated  materila detail */}

              {refrigeratedSaveMaterailModal && (
                <div className="modiFy">
                  {refrigeratedMaterials.map((material) => (
                    <div key={material.id}>
                      <div className="mod-flex">
                        <span className="numberText">{++count}</span>
                        <Link
                          className="me-3 edlink"
                          onClick={() => handleRefrigeratedEdit(material.id)}
                        >
                          <Image className="editimg" src={EditIcon} alt="" />
                        </Link>
                        <Link
                          className="edlink"
                          onClick={() => handleRefrigeratedDelete(material.id)}
                        >
                          <Image
                            className="editimgt edlink"
                            src={TrashIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Material</th>
                            <th>Temperature</th>
                            <th>Number of pallets</th>
                            <th>Size of pallets</th>
                            <th>Height of pallets</th>
                            <th>Total weight</th>
                            <th>MSDS included </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={material.id}>
                            <td>{material.materialName}</td>
                            <td>{material.temprature}</td>
                            <td>{material.numberOfPallets}</td>
                            <td>{material.sizeOfPallets}</td>
                            <td>{material.heightOfPallets}</td>
                            <td>{`${material.totalWeightOfPallets} Kg`}</td>
                            <td>{material.msds == "Yes" ? "Yes" : "No"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              )}

              {refrigeratedAddMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">{++count}</span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>

                  <div className="inputFlex">
                    <div className="kgflex">
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Temperature"
                        // reqstart="*"
                        value={temprature}
                        onChange={(text) => setTemperature(text.target.value)}
                      />
                      <span>°C</span>
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Number of pallets"
                        reqstart="*"
                        value={numberOfPallets}
                        onChange={(text) =>
                          setNumberOfPallets(text.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Size of pallets"
                        reqstart="*"
                        value={sizeOfPallets}
                        onChange={(text) => setSizeOfPallets(text.target.value)}
                      />
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Height of pallets"
                        value={heightOfPallets}
                        onChange={(text) =>
                          setHeightOfPallets(text.target.value)
                        }
                      />
                    </div>

                    <div className="kgflex">
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Total weight"
                        reqstart="*"
                        value={totalWeightOfPallets}
                        onChange={(text) =>
                          setTotalWeightOfPallets(text.target.value)
                        }
                      />
                      <span>Kg</span>
                    </div>
                  </div>

                  <div className="custom_radio">
                    <h5>
                      MSDS included <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="radio"
                        name="sds"
                        checked={msds == "Yes" && "Yes"}
                        className="card-input-element"
                        onClick={() => handleMSDS("Yes")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">Yes</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="sds"
                        checked={msds == "No" && true}
                        className="card-input-element"
                        onClick={() => handleMSDS("No")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">No</div>
                      </div>
                    </label>
                  </div>

                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={() => handleRefrigeratedSave()}
                  >
                    Save
                  </Button>
                </div>
              )}

              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() =>
                  setRefrigeratedAddMaterialModal(!refrigeratedAddMaterialModal)
                }
              >
                + Add material
              </Button>

              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                value={otherInformation}
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}
        </div>

        <BreadCrumbHeader image={LocationIcon} title="LOADING INFORMATIONS" />

        {["checkbox"].map((type) => (
          <div key={`default-${type}`} className="mb-3 textColor">
            <Form.Check
              type={type}
              id={`default-${type}`}
              value={pickupLocation}
              checked={pickupLocation}
              label={`Appointment required at pick-up location`}
              onClick={() => setPickupLocation(!pickupLocation)}
            />
          </div>
        ))}

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Reference"
              placeholder="Enter a reference"
              type="text"
              onChange={(text) => setReference(text.target.value)}
            />
            {referenceError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {referenceError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              onChange={(text) => setCompanyName(text.target.value)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextInputWithLabel
                      {...getInputProps({
                        placeholder: "Enter an Address",
                        className: "location-search-input",
                        label: "Address",
                        reqstart: "*",
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions &&
                          suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            return (
                              <li
                                style={{ cursor: "pointer" }}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <FaLocationDot />
                                <span>{suggestion.description}</span>
                              </li>
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Date (dd-mm-yyyy)"
                  placeholder="DD.MM.YYYY"
                  type="date"
                  reqstart="*"
                  onChange={(text) => setDate(text.target.value)}
                />
                {dateError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {dateError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                {/* <TextInputWithLabel
                  label="Hour"
                  placeholder="HH:MM"
                  type="time"
                  onChange={(text) => setTime(text.target.value)}
                /> */}
                <label class="form-label">Hour</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label=""
                      minutesStep={1}
                      onChange={handleTimeChange}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {timeError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {timeError}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setContactName(text.target.value)}
            />
            {contactNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text.target.value);
                }}
              />
            </div>

            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setContactEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
        </Row>
        <div className="mb-2"></div>
        <BreadCrumbHeader image={DeliveryIcon} title="DELIVERY INFORMATIONS" />

        {["checkbox"].map((type) => (
          <div key={2} className="mb-3 textColor">
            <Form.Check
              type={type}
              id={2}
              value={deliveryLocation}
              checked={deliveryLocation}
              label={`Appointment required at delivery location`}
              onClick={() => setDeliveryLocation(!deliveryLocation)}
            />
          </div>
        ))}

        <div className="custom_radio">
          <h5>
            Delivery type <span className="requiresLable">*</span>
          </h5>
          <label>
            <input
              type="radio"
              name="product"
              className="card-input-element"
              onClick={() => handeleDeliveryType("standard")}
            />
            <div className="card-input">
              <div className="panel-heading">Standard</div>
            </div>
          </label>

          <label>
            <input
              type="radio"
              name="product"
              className="card-input-element"
              onClick={() => handeleDeliveryType("express")}
            />
            <div className="card-input">
              <div className="panel-heading">Express</div>
            </div>
          </label>
          {deliveryTypeError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {deliveryTypeError}
            </div>
          ) : null}
        </div>

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Reference"
              placeholder="Enter a reference"
              type="text"
              onChange={(text) => setDeliveryRefernce(text.target.value)}
            />
            {deliveryRefernceError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryRefernceError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              onChange={(text) => setDeliveryCompanyName(text.target.value)}
            />
            {deliveryCompanyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryCompanyNameError}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <PlacesAutocomplete
                value={deliveryAddress}
                onChange={setDeliveryAddress}
                onSelect={handleSelectDelivery}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextInputWithLabel
                      {...getInputProps({
                        placeholder: "Enter an Address",
                        className: "location-search-input",
                        label: "Address",
                        reqstart: "*",
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions &&
                          suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            return (
                              <li
                                style={{ cursor: "pointer" }}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <FaLocationDot />
                                <span>{suggestion.description}</span>
                              </li>
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {deliveryAddressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryAddressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Date (dd-mm-yyyy)"
                  placeholder="DD.MM.YYYY"
                  type="date"
                  reqstart="*"
                  onChange={(text) => setDeliveryDate(text.target.value)}
                />
                {deliveryDateError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryDateError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                {/* <TextInputWithLabel
                  label="Hour"
                  placeholder="HH:MM"
                  type="time"
                  onChange={(text) => setDeliveryTime(text.target.value)}
                /> */}
                <label class="form-label">Hour</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label=""
                      minutesStep={1}
                      onChange={handledeliveryTimeChange}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                {deliveryTimeError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryTimeError}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setDeliveryContactName(text.target.value)}
            />
            {deliveryContactNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={deliveryContactPhone}
                onChange={(text) => {
                  setDeliveryContactPhone(text.target.value);
                }}
              />
            </div>
            {deliveryContactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setDeliveryContactEmail(text.target.value)}
            />
            {deliveryContactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactEmailError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={() => onCancelClick(props)}
          >
            Cancel
          </Button>

          <Button
            style={{ width: 170 }}
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => {
              if ((carrierName.trim() === "" && carrierEmail.trim() === "") || clientName.trim() === "") {
                toast("Please select carrier or enter carrier email and also select client");
              } else {
                onSubmit(props);
              }
            }}
            disabled={direct_quote_submit_loading}
          >
            {direct_quote_submit_loading ? <Loader /> : "Validate quote"}
          </Button>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}

export default Quotes;
